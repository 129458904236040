<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col md="10" sm="10" cols="12">
        <v-card
          elevation="4"
          outlined
          color="secondary"
          min-height="50vh"
          dark
        >
            <v-card-title class="text-success">
              {{$ml.get('useq')}}
            </v-card-title>
            <v-card-subtitle class="text-subtitle">
              {{$ml.get('signin')}}
            </v-card-subtitle>
            <v-card-text class="text-text">
                <v-row justify="center">
              <v-col md="10" cols="12">          
                <v-form
                    ref="form"
                    v-model="valid" 
                    @submit.prevent="signIn()"
                  >
                    <v-text-field
                        v-model="clientID"
                        :counter="50"
                        :rules="clientRules"
                        label="Client ID"
                        outlined
                        required
                        background-color="primary"
                        color="white"
                    ></v-text-field>
                    <!--<v-text-field
                        v-model="username"
                        :counter="50"
                        label="Username"
                        outlined
                        background-color="primary"
                        color="white"
                    ></v-text-field>-->

                    <v-row justify="space-around">
                      <v-col cols="auto">
                        <v-btn
                          :disabled="!valid"
                          color="success"
                          @click="signIn"
                        >
                          {{$ml.get('signin')}}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
        </v-card>
      </v-col>


    </v-row>

  </v-container>
</template>

<script>
import axios from 'axios';
import {BASE_URL} from '@/services/base-url';
var Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9\+\/\=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}
  export default {
    name: 'Home',
    data: () => ({
        baseUrl: 'http://localhost:8087',
        valid: false,
        clientID: '',
        username: '',
        clientRules: [
            v => !!v || 'Client ID is required'
        ],
    }),
    mounted() {
      
    },
    methods: {
     signIn() {
       let req = {
         url: `${BASE_URL}/api/clients/` + this.clientID,
         method: 'get'
       }
       axios(req).then((response) => {
         let data = response.data;
         if (data.destination) {
           let newURL = data.destination;
           if (this.username && this.username !== "") {
             newURL += '/' + Base64.encode(this.username);
           }
           window.location = newURL;
         }
         console.log(data);
       }).catch(err => {
         console.log(err);
       })

     }
    }
  }
</script>

<style scoped>
.text-success {
  color: #14BF98;
}
.text-subtitle {
  color: #fff !important;
  font: 700 1.75rem/2.125rem "Montserrat", sans-serif;
}

.text-text {
  color: #dfe5ec !important;
  font: 400 1rem/1.5625rem "Open Sans", sans-serif;
}

.borderRight {
    border-right: solid 1px grey;
}
</style>
