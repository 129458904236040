<template>
  <Login/>
</template>

<script>
  import Login from '../components/Login'

  export default {
    name: 'LoginPage',

    components: {
      Login,
    },
  }
</script>
